
import LogoSVG from '@/components/svg/LogoSVG.vue'
import VerticalLongSVG from '@/components/svg/detail/VerticalLongSVG.vue'
import Saw from '@/components/parts/Saw.vue'
import BtnPrimaryLink from '@/components/parts/btn/BtnPrimaryLink.vue'
export default {
  components: {
    // HeadingSM,
    LogoSVG,
    VerticalLongSVG,
    Saw,
    BtnPrimaryLink,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    dark: {
      type: Boolean,
      default: false,
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      jobs: [],
      tl: null,
    }
  },

  // async fetch() {
  //   try {
  //     const client = this.$apollo.getClient()
  //     const response = await client.query({
  //       query: jobRolesQuery,
  //     })
  //     if (response?.data?.jobRoles?.data) {
  //       this.jobs = response.data.jobRoles.data
  //       this.$nextTick(() => {
  //         this.$ScrollTrigger.refresh()
  //       })
  //     }
  //   } catch (e) {
  //     this.articles = []
  //     this.loading = false
  //   }
  // },
  // fetchOnServer: false,
  mounted() {
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        start: 'top bottom',
        // toggleActions: 'play reset play reverse',
      },
      onComplete: () => {
        heading.revert()
      },
    })
    this.tl.scrollTrigger.disable()

    this.tl.to(this.$refs.mask, { xPercent: -100, duration: 0.3 })

    const heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines' })
    this.tl.from(heading.words, {
      opacity: 0,
      stagger: {
        each: 0.1,
        from: 'start',
        grid: 'auto',
        ease: 'power2.inOut',
      },
    })
    this.$refs.logo && this.tl.from(this.$refs.logo.$el, { opacity: 0, xPercent: -10, duration: 0.2 }, '<')
    this.$refs.body && this.tl.from(this.$refs.body, { opacity: 0, duration: 0.2 }, '>')

    this.$emit('sectionMounted')
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
  },
}
